import { HeadFC, PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  ColoredLogo,
  ElementCard,
  NeutralLogo,
  Seo,
  VersionCard,
} from '../../../components';
import { DesignLayout } from '../../../components/design/DesignLayout';
import { SeparatorLineStyle } from '../../../components/design/cards/style';
import { BodyTextStyle } from '../../../components/design/typography/style';
import { breakpoints } from '../../../theme';

const BrandPage: FC<PageProps> = () => {
  return (
    <DesignLayout>
      <VersionCard title="Brand" />

      <ElementCard title="Components">
        <BlackContainer1Style>
          <div>
            <ColoredLogo />
          </div>
          <div>
            <NeutralLogo color="white" />
          </div>
          <div className="bg-white">
            <NeutralLogo color="black" />
          </div>
          <div>
            <ColoredLogo monogram />
          </div>
          <div>
            <NeutralLogo color="white" monogram />
          </div>
          <div className="bg-white">
            <NeutralLogo color="black" monogram />
          </div>
        </BlackContainer1Style>

        <SeparatorLineStyle />

        <Container1Style>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Color
            </BodyTextStyle>
            <BlackBrandContainerStyle>
              <ColoredLogo />
            </BlackBrandContainerStyle>
          </div>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              White
            </BodyTextStyle>
            <BlackBrandContainerStyle>
              <NeutralLogo color="white" />
            </BlackBrandContainerStyle>
          </div>
          <div>
            <BodyTextStyle fontSize="bodyText1" fontWeight="medium">
              Black
            </BodyTextStyle>
            <BrandContainerStyle>
              <NeutralLogo color="black" />
            </BrandContainerStyle>
          </div>
        </Container1Style>
      </ElementCard>
    </DesignLayout>
  );
};
export default BrandPage;
export const Head: HeadFC = () => <Seo title="Theme" />;

const BrandContainerStyle = styled.div`
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.neutral[20]};
  border-radius: 0.625rem;
  display: flex;
  height: 11.25rem;
  justify-content: center;
  width: 100%;
`;

const BlackBrandContainerStyle = styled(BrandContainerStyle)`
  background-color: ${({ theme }) => theme.colors.black};
`;

const Container1Style = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  div {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;
    gap: 1.875rem;
  }
`;

const BlackContainer1Style = styled(Container1Style)`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 0.625rem;
  padding: 2.5rem;

  .bg-white {
    background-color: white;
    display: flex;
    justify-content: center;
  }

  div {
    width: fit-content;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    padding: 3.5rem;
  }
`;
